import React, { useEffect } from 'react';
import "./EliteSponsor.css";
import IMAGES from '../../assets';
import { Helmet } from "react-helmet-async";


function EliteSponsors() {
  useEffect(() => {
    document.title = "Elite Sponsors";
  }, [])
  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="description" content="ELITE SPONSORS - ELEVEN10 GEAR, Benchmade Knives, CIRCLESQUARED, SHOREHAVEN"/>
        <link rel="canonical" href="/elite-sponsors"/>
      </Helmet>
      <div className='EliteMainDiv'>
        <div className='EliteContainer'>
          <div className='EliteTitle'>
            <div className='ELiteTitleText'>
            ELITE SPONSORS
            </div>
          </div>
          <div className='EliteImgText'>
            <div className='EliteImgDiv'>
              <a href='https://1110gear.com/' target="_blank">
                <img className='EliteImg' src={IMAGES.EliteSa} alt='Eleven10'/>
              </a>
            </div>
            <div className='EliteTextBox'>
              <div className='EliteTextTitle'>ELEVEN10 GEAR</div>
              <div className='EliteTextContent'>Eleven 10®, based in Cleveland, Ohio, is a manufacturer of high-quality self-aid/buddy-aid gear that is proudly made in the USA. We were founded by 2 brothers: a former Reconnaissance Marine and current tactical paramedic, the other is a longtime police officer and an active SWAT commander. This gives us the unique insight of MIL/LE Special Operations, trauma medicine, and the street cop. We’ve taken our personal experiences and designed medical carrying equipment based on the lessons learned from the streets of America & the conflicts in the Middle East. 
              <span><a className='EliteTextLink' href='https://1110gear.com/' target="_blank">www.1110gear.com</a></span>
              </div>
            </div>
          </div>

          {/* <div className='EliteImgText'>
            <div className='EliteImgDiv'>
              <a href='https://goaxil.com/' target="_blank">
                <img className='EliteImg' src={IMAGES.EliteSb} alt='AXIL'/>
              </a>
            </div>
            <div className='EliteTextBox'>
              <div className='EliteTextTitle'>AXIL HEARING PERFORMANCE</div>
              <div className='EliteTextContent'>AXIL operates with a clear vision that innovation is the heart of its business. With the fast-paced level of product and technology expansion, AXIL has lead the way in providing the very best product designs and advancements to the sporting goods industry. AXIL has and always will deliver the finest performing hearing enhancement and protection devices available. From ultra comfortable fitting processes, to stereo quality sound performance, to reliable & durable engineering – AXIL continues to redefine the premium products in the market. 
              <span><a className='EliteTextLink' href='https://goaxil.com/' target="_blank">goaxil.com</a></span>
              </div>
            </div>
          </div> */}

          <div className='EliteImgText'>
            <div className='EliteImgDiv'>
              <a href='https://www.benchmade.com/' target="_blank">
                <img className='EliteImg' src={IMAGES.AT5} alt='Benchmade Knives'/>
              </a>
            </div>
            <div className='EliteTextBox'>
              <div className='EliteTextTitle'>Benchmade Knives</div>
              <div className='EliteTextContent'>For two decades Benchmade has been designing and manufacturing the world's finest knives and edged tools. Our products are developed and tested for extreme duty and are the preferred cutting tools for Elite Military Troops and Public Safety Professionals around the globe. You can count on your Benchmade to get the job done. USA Proud!
              <span><a className='EliteTextLink' href='https://www.benchmade.com/' target="_blank">www.benchmade.com</a></span>
              </div>
            </div>
          </div>

          <div className='EliteImgText'>
            <div className='EliteImgDiv'>
              <a href='https://www.circlesquaredalts.com' target="_blank">
                <img className='EliteImg' src={IMAGES.EliteSc} alt='AXIL'/>
              </a>
            </div>
            <div className='EliteTextBox'>
              <div className='EliteTextTitle'>CIRCLESQUARED</div>
              <div className='EliteTextContent'>Founded in 2014, Circle Squared Alternative Investments LLC (CSQ) is a privately held Real Estate Investment firm. Founder Jeff Sica’s vision and belief is that sophisticated investors want and deserve access to alternatives to the public markets. Working with accomplished real estate developers with track records and deep experience while creating strong investment partnerships, CSQ has connected family offices and high net worth individuals with real estate projects that have traditionally only been available to institutional investors. CSQ is dedicated to helping you bridge the gap between your investment goals and your ability to achieve them.
              <span><a className='EliteTextLink' href='https://www.circlesquaredalts.com' target="_blank">www.circlesquaredalts.com</a></span>
              </div>
            </div>
          </div>

          <div className='EliteImgText' style={{border:"none"}}>
            <div className='EliteImgDiv'>
              <a href='https://shorehavenwealth.com/' target="_blank">
                <img className='EliteImg' src={IMAGES.EliteSd} alt='AXIL'/>
              </a>
            </div>
            <div className='EliteTextBox'>
              <div className='EliteTextTitle'>SHOREHAVEN</div>
              <div className='EliteTextContent'>As your advocate and fiduciary, we will custom-build a financial plan for you – one that is designed to help you protect, grow, and transition your wealth.
              <span><a className='EliteTextLink' href='https://shorehavenwealth.com/' target="_blank">shorehavenwealth.com</a></span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default EliteSponsors