import React, { useEffect } from "react";
import "./Partners.css";
import IMAGES from "../../assets";
import { Helmet } from "react-helmet-async";


function Partners() {
  useEffect(() => {
    document.title = "Partners";
  }, []);
  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="description" content="OUR PARTNERS - TUNNELS TO TOWERS and MOBILE TRAUMA UNIT"/>
        <link rel="canonical" href="/partners"/>
      </Helmet>
      <div className="MainPartners">
        <div className="Partners">
          <div className="PartnersTitle">OUR PARTNERS</div>
          <div className="PartnerFirstDiv">
            <div className="PartnerImageLinkDiv1">
              <a href="https://t2t.org/" target="_blank">
                <img className="partnerImage1" src={IMAGES.TunnelsToTowers} alt="Tunnels to towers" />
              </a>
            </div>
            <div className="PartnerTitleAndContent1">
              <div className="PartnerTitleTxt">TUNNELS TO TOWERS</div>
              <div className="PartnerTitleTxt2">
                The Tunnel to Towers Foundation honors the sacrifice of
                firefighter Stephen Siller who laid down his life to save others
                on September 11, 2001. They also honor our military and first
                responders who continue to make the supreme sacrifice of life
                and limb for our country. Over $250 Million Dollars donated to
                various programs. The MTU is honored to be working alongside
                this foundation.
              </div>
            </div>
          </div>
          <div className="PartnerFirstDiv2">
            <div className="PartnerImageLinkDiv1">
              <a href="https://goaxil.com/" target="_blank">
                <img className="partnerImage1T" src={IMAGES.TaskForceAntal} alt="Tunnels to towers" />
              </a>
            </div>
            <div className="PartnerTitleAndContent1">
              <div className="PartnerTitleTxt">MOBILE TRAUMA UNIT</div>
              <div className="PartnerTitleTxt2 PartnerTitleTxtMargin">
              The Anti-Terrorism Council, Inc. is a not-for-profit organization 501(c)(3) comprised of all volunteer medical personnel. It pioneered tactical medical support in New York City. Started in 2004, the Mobile Trauma Unit, or MTU, has provided medical support to law enforcement entities including the United States Secret Service, Department of State Diplomatic Security Service, & others during national security events, high-level warrant executions & training exercises. The NYC ATS has been activated for the United Nations General Assembly, Presidential visits to New York City, the Papal visit to New York City, the Dalai Lama U.S visit, Presidential Debates, & to serve as the medical team for other foreign dignitaries.
              </div>
              <div className="PartnerTitleTxt2 PartnerTitleTxtMargin">
              There is currently no other organized medical team in the region with comparable on-scene immediate surgical capabilities.
              </div>
              <div className="PartnerTitleTxt2">
              Intended to supplement EMS & other field units by furnishing access to advanced surgical/operative care, the ATS evaluates and treats patients who may not be able to be moved for a variety of reasons (i.e., pinned down by a building collapse, a subway or industrial accident, and/or because of high-level security issues). The unit is comprised of a voluntary core group of surgeons, emergency medicine physicians, nurses, paramedics, EMTs, & ancillary staff.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
