import React from "react";
import "./Events.css";
import IMAGES from "../../assets";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


function Events() {
  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="description" content="5X CEO/Founder and proven visionary of AI Platforms for more than 30 years, with first-to market solutions that have changed the world we live in." />
        <link rel="canonical" href="/events-1909"/>
      </Helmet>
      <div className="EventEliteMainDiv">
        <div className="EventEliteContainer">
          <div className="EventEliteTitle">
            <div className="EventELiteTitleText">EVENTS</div>
          </div>
          <div className="EventEliteImgText">
            <div className="EventEliteImgDiv">
              <div className="EventDateText">November 12, 2024</div>
              <div className="EventTimeText">6PM</div>
            </div>
            <div className="EventEliteTextBox">
              <div className="EventEliteTextTitle">ATC Focus Dinner</div>
              <div className="EventEliteTextContentTown">
                Benjamin Prime Steakhouse
              </div>
              <div className="EventEliteTextContentFullAddress">
                23 E 40th St., Between Park and Madison Avenue, New York City,
                NY 10016
              </div>
              <div className="EventLinkBook">
                  <div className="OurStoryMainDiv1">
                    <Link className="ourstoryLink1" to="/learn-more">
                      <div className="OurStoryButtonDiv1">LEARN MORE</div>
                    </Link>
                  </div>
              </div>
            </div>
          </div>

          <div className="EventEliteImgText">
            <div className="EventEliteImgDiv">
              <div className="EventDateText">April 10-11, 2025</div>
              <div className="EventTimeText">8:00AM-4:00PM</div>
            </div>
            <div className="EventEliteTextBox">
              <div className="EventEliteTextTitle">
                Annual NYC Anti-Terrorism Symposium
              </div>
              <div className="EventEliteTextContentTown">
                New York Downtown Marriott
              </div>
              <div className="EventEliteTextContentFullAddress">
                New York, NY 10006
              </div>
              {/* <div className="EventLinkBook">
                <a href="https://book.passkey.com/gt/219564250?gtid=8e59348e3b61b0fb1595f35e3617d71c" target="_blank" className="EventLinkBookHref">
                  <div className="EventLinkBookText">
                    More Info
                  </div>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
