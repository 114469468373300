import React from "react";
import "./Home.css";
import IMAGES from "../../assets";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <div className="HomeMainDiv">
      <Helmet>
        <title></title>
        <meta name="description" content="The Anti-Terrorism Symposium is designed to provide you with the knowledge, skills, tactics, and equipment needed to survive in an increasingly uncertain world. " />
        <link rel="canonical" href="/"/>
      </Helmet>
      {/* Banner Image Start */}
      <div className="BannerDivHome">
        <div className="DataBanner">
          <div className="BannerTitle">
            NYC Anti-Terrorism Symposium Conference
          </div>
          <div className="BannerSubTitle">New York Downtown Marriott</div>
          <div className="AddressBanner">
          New York, NY 10006
          </div>
          <div className="BannerDate">April 10-11, 2025</div>
          <div className="BannerDate1">8:00AM-4:00PM</div>
          <div className="BannerButtonDiv">
            {/* <Link className="ourstoryLink" to="/events-1909">
              <div className="BannerBtton">Learn More</div>
            </Link> */}
          </div>
        </div>
      </div>
      {/* Banner Image End */}

      {/* Elite Sponsor Start */}
      <div
        style={{
          backgroundColor: "#ffffff",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="EliteSponsorMain">
          <div className="EliteSpnsorHomeText">ELITE SPONSORS</div>
          <div className="EliteSpnsorImagesHome">
            <div className="EliteSponsorHomeImageDiv">
              <a href="https://1110gear.com/" target="_blank">
                <img
                  className="HomeEliteImg"
                  src={IMAGES.EliteSa}
                  alt="Eleven10"
                />
              </a>
            </div>
            <div className="EliteSponsorHomeImageDiv">
              <a href="https://goaxil.com/" target="_blank">
                <img className="HomeEliteImg" src={IMAGES.AT5} alt="AXIL" />
              </a>
            </div>
            <div className="EliteSponsorHomeImageDiv">
              <a href="https://www.circlesquaredalts.com" target="_blank">
                <img className="HomeEliteImg" src={IMAGES.EliteSc} alt="AXIL" />
              </a>
            </div>
            <div className="EliteSponsorHomeImageDiv">
              <a href="https://shorehavenwealth.com/" target="_blank">
                <img className="HomeEliteImg" src={IMAGES.EliteSd} alt="AXIL" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Elite Sponsor End */}

      {/* HomePolice Start */}
      <div className="HomePolice">
        <div className="PoliceContent">
          <div className="PoliceContentInside">
            <div className="FocusTitle">OUR FOCUS</div>
            <div className="Symposium">THE SYMPOSIUM MISSION</div>
            <div className="ArmyContent">
              The Anti-Terrorism Symposium is designed to provide you with the
              knowledge, skills, tactics, and equipment needed to survive in an
              increasingly uncertain world. Our goal is that you leave with new
              skills and information leaving you adaptable to any situation.
            </div>
            <div className="OurStoryMainDiv">
              <Link className="ourstoryLink" to="/about">
                <div className="OurStoryButtonDiv">OUR STORY</div>
              </Link>
            </div>
          </div>
        </div>

        <div className="PolicePic">
          <img className="PoliceImage" src={IMAGES.HomeArmy} alt="Police" />
        </div>
      </div>
      {/* HomePolice End */}
      <div className="OurESponsorsTitleDiv">
        <div className="OurESponsorsTitleDiv">OUR PARTNERS</div>
      </div>

      <div className="HomeOurPartnersImagesDiv">
        <div className="HomeOurPartnersImg">
          <div className="HomeTunneltoTowers">
            <img
              className="HomePartnerImages"
              src={IMAGES.TunnelsToTowers}
              alt="Tunnels To Towers"
            />
          </div>
          <div className="HomeTaskForceAntal">
            <img
              className="HomePartnerImagesTFA"
              src={IMAGES.TaskForceAntal}
              alt="Task Force Antal"
            />
          </div>
        </div>
      </div>

      {/* Out Exhibiting Sponsors start */}
      <div className="OurESponsorsTitleDiv">
        <div className="OurESponsorsTitleDiv">OUR EXHIBITING SPONSORS</div>
      </div>
      <div className="OurExhibitingSponsorsImagesMain">
        <div className="OurExhibitingSponsorsImages">
          <div className="SponsorCarousel">
            <div className="HomeEsponsorImgDiv">
              <img
                className="HomeEsponsorImg"
                src={IMAGES.AT1}
                alt="Captioncall by Sorenson"
              />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT2} alt="b" />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT3} alt="c" />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT4} alt="d" />
            </div>

            {/* <div className="HomeEsponsorImgDiv" style={{paddingLeft:"35px"}}>
              <img className="HomeEsponsorImg" src={IMAGES.AT5} alt="e" />
            </div> */}

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT6} alt="6" />
            </div>

            <div className="HomeEsponsorImgDiv newImagesHomePadding">
              <img className="HomeEsponsorImgAdd" src={IMAGES.EmpireOriginalDesigns} alt="01" />
            </div>
            {/* <div className="HomeEsponsorImgDiv newImagesHomePadding">
              <img className="HomeEsponsorImgAdd" src={IMAGES.ZenithAerotech} alt="02" />
            </div> */}
            <div className="HomeEsponsorImgDiv newImagesHomePadding">
              <img className="HomeEsponsorImgAdd" src={IMAGES.Unity} alt="03" />
            </div>
            <div className="HomeEsponsorImgDiv newImagesHomePadding">
              <img className="HomeEsponsorImgAdd" src={IMAGES.Vaultek} alt="04" />
            </div>
            <div className="HomeEsponsorImgDiv newImagesHomePadding">
              <img className="HomeEsponsorImg" src={IMAGES.BlackRiver} alt="05" />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT7} alt="06" />
            </div>

            {/* <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT8} alt="8" />
            </div> */}

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT9} alt="9" />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT10} alt="10" />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT11} alt="11" />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT12} alt="12" />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT13} alt="13" />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT14} alt="14" />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT15} alt="15" />
            </div>

            {/* <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT16} alt="16" />
            </div> */}

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT17} alt="17" />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT18} alt="18" />
            </div>

            {/* <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT19} alt="19" />
            </div> */}

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT20} alt="20" />
            </div>

            {/* <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT21} alt="21" />
            </div>

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT22} alt="22" />
            </div> */}

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT23} alt="23" />
            </div>

            {/* <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT24} alt="24" />
            </div> */}

            <div className="HomeEsponsorImgDiv">
              <img className="HomeEsponsorImg" src={IMAGES.AT25} alt="25" />
            </div>
          </div>
        </div>
      </div>
      {/* Out Exhibiting Sponsors End */}

      <div className="MeetTheNycMainDiv">
        <div className="MeetTheNycMainData">
          <div className="MeetTheNycMainDataTitle">Meet the NYC ATC Team</div>
          <div className="MeetTheNycMainDataContent">
          The Anti-terrorism Council (ATC) and it's team brings together leaders and decision makers in the field of law enforcement, government, business, healthcare and the private sector.  The ATC is a not-for-profit organization 501(c)(3) comprised of all volunteer personnel.
          </div>
        </div>
      </div>

      <div className="HomeThreeImagesMain">
        <div className="HomeThreeImages">
          <div className="HomeThreeImagesDiv1">
            <img
              src={IMAGES.Maurizio}
              alt="Dr. Maurizio Miglietta"
              className="HomeThreeImgs"
            />
            <div className="HomeRow1ImagesText">Dr. Maurizio Miglietta</div>
            <div className="HomeRowImageTitle">Director</div>
          </div>
          <div className="HomeThreeImagesDiv2">
            <img
              src={IMAGES.Michael}
              alt="Michael Vaiani"
              className="HomeThreeImgs"
            />
            <div className="HomeRow1ImagesText">Michael Vaiani</div>
            <div className="HomeRowImageTitle">Founder</div>
          </div>
          <div className="HomeThreeImagesDiv3">
            <img
              src={IMAGES.DianeChange}
              alt="Diane Barton"
              className="HomeThreeImgs"
            />
            <div className="HomeRow1ImagesText">Diane Barton</div>
            <div className="HomeRowImageTitle">Coordinator</div>
          </div>
        </div>
      </div>

      <div className="mapClass">
        <div className="MapClassDiv">
          <div className="MapClassChilds">
            <div className="DianeBarton">
              <div className="DianeBartonTxt">Diane Barton</div>
              <div className="SymposiumCoordinator">Symposium Coordinator</div>
              <div className="Email">Email:</div>
              <div className="EmailAddress">
              diane.barton@atcouncil.us
              </div>
            </div>

            <div className="GetInTouchDiv">
              <div className="GetInTouch">Get In Touch</div>
              <div className="AssistanceHours">Assistance Hours</div>
              <div className="timinghome">Mon – Sat 9:00am – 8:00pm</div>
              <div className="timingDay">Sunday – CLOSED</div>
              <div className="PhoneNumberHome">Phone Number:</div>
              <div className="PhoneNumberData">(914) 760 - 9853</div>
            </div>
            <div className="MapImageInsideDiv">
              <div className="MapImageInner">
                <a
                  style={{ cursor: "pointer" }}
                  href="https://www.google.com/maps/place/New+York+Marriott+Downtown,+85+West+Street+At,+Albany+St,+New+York,+NY+10006,+United+States/@40.7095206,-74.0144862,15z/data=!4m6!3m5!1s0x89c25a1090488a95:0xb7522984917c278f!8m2!3d40.7095206!4d-74.0144862!16s%2Fm%2F0wfh21_"
                  target="_blank"
                >
                  <img
                    src={IMAGES.Map}
                    className="mapimg"
                    alt="Map of the location"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
