import { createContext, useState } from "react";

export const Context = createContext();

export const ContextProvider = ({children}) => {
    const [open, setOpen] = useState(false);
    const toggle = (flag) => { 
        setOpen(flag)
    }
    return (
        <Context.Provider value={{open,toggle}}>
            {children}
        </Context.Provider>
    )
}