import React, { useEffect } from "react";
import "./About.css";
import IMAGES from "../../assets";
import { Helmet } from "react-helmet-async";


function About() {
  useEffect(() => {
    document.title = "About us";
  }, []);

  return (
    <div className="AboutUs">
      <Helmet>
        <title></title>
        <meta name="description" content="The Anti-Terrorism Council, Inc. is a distinguished 501(c)(3) not-for-profit organization, fueled entirely by the dedication of our volunteer team."/>
        <link rel="canonical" href="/about"/>
      </Helmet>
      <div className="AboutUsMain">
        <div className="AvoutUsFirst">
          <div className="AvoutUsFirstTxt1">ABOUT US</div>
          <div className="AvoutUsFirstTxt2">OUR STORY</div>
          <div>
            <div className="AvoutUsFirstP">
              The Anti-Terrorism Council, Inc. is a distinguished 501(c)(3)
              not-for-profit organization, fueled entirely by the dedication of
              our volunteer team.
            </div>
            <div className="AvoutUsFirstP2 AvoutUsFirstP">
              Born from the lessons learned from the September 11, 2001 attacks,
              the imperative for national unity and preparedness against the
              specter of terrorism became undeniable. Throughout its history,
              the Anti-Terrorism Council (ATC) has been privileged to provide
              advanced training conducted by elite professionals, including
              former US Navy SEALs, operatives from France's GIGN (the French
              Counterterrorism Force), US Army Special Forces, the US Secret
              Service, FBI, DEA, among others. In every case the presenters are
              regarded subject matter experts in their field, infusing our
              programs with unparalleled realism and practicality.
            </div>
            <div className="AvoutUsFirstP">
              Participants in ATC programs emerge equipped with the most
              sophisticated tools, technologies, tactics, and insights, fully
              aware of the complex global security landscape. They gain not only
              knowledge but also a profound understanding of the necessity for
              continuous learning and improvement in the face of ever-evolving
              threats.
            </div>
            <div className="AvoutUsFirstTxt2" style={{ textAlign: "center" }}>
              Anti-Terrorism Council
            </div>
            <div className="AvoutUsFirstP">
              We must always be prepared to continue and remember 9/11, reunite
              the men and women who lived through those days and held the city
              together. Resource "in the fight" with education & training and
              never forget!
            </div>
            <div className="AboutUsDiv">
              <img
                src={IMAGES.ATCAboutUs}
                alt="About Us Images"
                className="AboutUsImages"
              />
            </div>
            <div className="FirstDivLine"></div>
          </div>
        </div>
        <div className="AvoutUsSecond">
          <div className="AvoutUsSecondTxt1">SYMPOSIUM’S HISTORY</div>
          <div>
            <div className="AvoutUsFirstP AvoutUsSecondP">
              In it’s 22nd year, the Anti-Terrorism Council (ATC) brings
              together leaders & decision-makers in the field of law
              enforcement, government, business, medicine & the private sector.
            </div>
            <div className="AvoutUsFirstP">
              The 1st Anti-Terrorism Symposium came about after United States
              Secret Service Special Agent Michael Vaiani used his Surefire
              flashlight during the events of 9/11 at the World Trade Center.
              Mike realized that the events and relationships established could
              form a networking opportunity for not only law enforcement but
              also for a wide variety of individuals. This mixing of dynamic
              individuals from the various sectors along with an expertise of
              speakers makes the Symposium a truly unique experience.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
