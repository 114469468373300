import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import IMAGES from "../../assets";
import "./Header.css";
import { Context } from "../../Context";

const menu = [
  {
    id: 1,
    title: "Home",
    path: "/",
  },
  {
    id: 2,
    title: "Events",
    path: "/events-1909",
  },
  {
    id: 3,
    title: "Speaker Roster",
    path: "/speaker-roster",
  },
  {
    id: 4,
    title: "Elite Sponsors",
    path: "/elite-sponsors",
  },
  {
    id: 5,
    title: "Exhibiting Sponsors",
    path: "/exhibiting-sponsor",
  },
  {
    id: 6,
    title: "About us",
    path: "/about",
  },
  {
    id: 7,
    title: "Partners",
    path: "/partners",
  },
  {
    id: 8,
    title: "Gallery",
    path: "/gallery",
  },
];

function Header() {
  const {open,toggle} = useContext(Context);

  return (
    <div className="HeaderParent">
      <div className="HeaderParentChild">
        <div className="HeaderParentLogo">
          <Link to="/"><img src={IMAGES.ATCLogo} alt="ATC Logo" className="HeaderLogo" /></Link>
        </div>
        <div className="HeaderParentMenu">
          {menu.map((nav) => (
              <div className="HeaderParentMenuItems" key={nav.id}>
                <NavLink 
                // className="MenuLink"
                className={({isActive})=>isActive ? "ActiveMenuLink" : "MenuLink"}
                 to={nav.path}>
                  {nav.title}
                </NavLink>
              </div>
          ))}
        </div>
        {!open && <div className="OpenMenuImage">
          <div className="openClose" onClick={()=>toggle(true)}><img src={IMAGES.Menu} alt="Open Menu"/></div>
        </div>}
        {open && <div className="HeaderMobileMenu">
        <div className="openClose" onClick={()=>toggle(false)}><img src={IMAGES.Close} alt="Open Menu"/></div>
          {menu.map((nav) => (
              <div className="MobileMenuItems" key={nav.id}>
                <Link onClick={()=>toggle(false)} className="MobileMenuLink" to={nav.path}>
                  {nav.title}
                </Link>
              </div>
          ))}
        </div>}
      </div>
    </div>
  );
}

export default Header;
