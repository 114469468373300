import React, { useEffect } from "react";
import "./ExhibitingSponsors.css";
import IMAGES from "../../assets";
import { Helmet } from "react-helmet-async";


function ExhibitingSponsors() {
  useEffect(() => {
    document.title = "Exhibiting Sponsors";
  }, []);
  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="description" content="EXHIBITING SPONSORS - Aimpoint, AXIL HEARING PERFORMANCE, B.E. MEYERS, Blue Line Beer Company, Clear Armor, Empire Original Designs,UNITY Tactical, Vaultek Safe"/>
        <link rel="canonical" href="/exhibiting-sponsor"/>
      </Helmet>
      <div className="EsEliteMainDiv">
        <div className="EsEliteContainer">
          <div className="EsEliteTitle">
            <div className="EsELiteTitleText">EXHIBITING SPONSORS</div>
          </div>
          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.actiontarget.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT1}
                  alt="Action Target"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Action Target</div>
              <div className="EsEliteTextContent">
                Action Target provides a full line of superior shooting range
                equipment including portable steel targets, turning targets,
                moving targets, bullet traps, shoothouses, and complete indoor
                ranges.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.actiontarget.com/"
                    target="_blank"
                  >
                    www.actiontarget.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.aimpoint.com/" target="_blank">
                <img className="EsEliteImg" src={IMAGES.AT2} alt="Aimpoint" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Aimpoint</div>
              <div className="EsEliteTextContent">
                Aimpoint is the recognized worldwide leader and originator of
                the red dot sighting technology. After more than 30 years of
                working closely with elite military and police forces,
                experienced hunters and marksmen around the world, Aimpoint red
                dot sights remain the No. 1 choice for combining speed and
                accuracy.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.aimpoint.com/"
                    target="_blank"
                  >
                    www.aimpoint.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://goaxil.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT3}
                  alt="AXIL HEARING PERFORMANCE"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">AXIL HEARING PERFORMANCE</div>
              <div className="EsEliteTextContent">
                AXIL operates with a clear vision that innovation is the heart
                of its business. With the fast-paced level of product and
                technology expansion, AXIL has lead the way in providing the
                very best product designs and advancements to the sporting goods
                industry. AXIL has and always will deliver the finest performing
                hearing enhancement and protection devices available. From ultra
                comfortable fitting processes, to stereo quality sound
                performance, to reliable & durable engineering – AXIL continues
                to redefine the premium products in the market.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://goaxil.com/"
                    target="_blank"
                  >
                    goaxil.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://bemeyers.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT4}
                  alt="B.E. MEYERS"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">B.E. MEYERS</div>
              <div className="EsEliteTextContent">
                B.E. Meyers & Co. Defense Systems leverages over four decades of
                experience in advanced photonics, and precision manufacturing,
                to equip the modern warfighter with some of the most advanced
                technology available. This sector encompasses weapon enabling
                components for precision rifle, individual carbine, and various
                remote/crew served platforms, as well as a full suite of NIR and
                visible laser aiming solutions. In addition, B.E. Meyers & Co.
                defense systems maintains its dominance as a leader in Hail +
                Warning solutions for various military applications.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://bemeyers.com/"
                    target="_blank"
                  >
                    www.bemeyers.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          {/* <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.benchmade.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT5}
                  alt="Benchmade Knives"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Benchmade Knives</div>
              <div className="EsEliteTextContent">
                For two decades Benchmade has been designing and manufacturing
                the world's finest knives and edged tools. Our products are
                developed and tested for extreme duty and are the preferred
                cutting tools for Elite Military Troops and Public Safety
                Professionals around the globe. You can count on your Benchmade
                to get the job done. USA Proud!
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.benchmade.com/"
                    target="_blank"
                  >
                    www.benchmade.com
                  </a>
                </span>
              </div>
            </div>
          </div> */}

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://bluelinebeercompany.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT6}
                  alt="Blue Line Beer Company"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Blue Line Beer Company</div>
              <div className="EsEliteTextContent">
                We are an American company who’s goal it is to support law
                enforcement while brewing great beer. We hope others will follow
                us by supporting law and order and authentic American values. So
                raise your glass and satisfy your tastebuds knowing that a
                portion of our proceeds go to police charities around the
                country. The names of our beers speak for themselves so we hope
                you’ll enjoy a laugh while drinking a phenomenal craft beer.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://bluelinebeercompany.com/"
                    target="_blank"
                  >
                    bluelinebeercompany.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://clear-armor.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT7}
                  alt="Clear Armor"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Clear Armor</div>
              <div className="EsEliteTextContent">
                TruArmor is at the forefront of vehicle innovation security
                solutions that protects and secures your most valuable assets,
                your family, friends and property. We use industry leading
                technology in conjunction with a unique multilayered world
                patented made in the USA glass system that meets OEM Standards
                and is DOT Certified.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://clear-armor.com/"
                    target="_blank"
                  >
                    www.clear-armor.com
                  </a>
                </span>
              </div>
            </div>
          </div>


          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.jointheempire.com/" target="_blank">
                <img
                  className="EsEliteImg1"
                  src={IMAGES.EmpireOriginalDesigns}
                  alt="Empire Original Designs"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Empire Original Designs</div>
              <div className="EsEliteTextContent">
              Empire Original Designs was established in 2014 in Brooklyn, New York by Damian Korman. He is a retired veteran law enforcement officer from the New York City Police Department. Damian took his knowledge of graphic design and his experience in law enforcement and merged the two fields to establish unique and creative products.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.jointheempire.com/"
                    target="_blank"
                  >
                    www.jointheempire.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          {/* <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://zenithaerotech.com/" target="_blank">
                <img
                  className="EsEliteImg1"
                  src={IMAGES.ZenithAerotech}
                  alt="Zenith Aerotech"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Zenith Aerotech</div>
              <div className="EsEliteTextContent">
              Zenith Aerotech is an Afton, Virginia-based company that designs and builds Tethered Drone Systems (TDS) and Tethered Aerial Vehicles (TAVs) for use in public safety, defense, industrial, and media applications. We leverage a deep understanding TAV operation, components, and technologies to provide highly customizable solutions.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://zenithaerotech.com/"
                    target="_blank"
                  >
                    zenithaerotech.com
                  </a>
                </span>
              </div>
            </div>
          </div> */}

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.unitytactical.com/" target="_blank">
                <img
                  className="EsEliteImg1"
                  src={IMAGES.Unity}
                  alt="UNITY Tactical"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">UNITY Tactical</div>
              <div className="EsEliteTextContent">
              UNITY Tactical designs components that integrate tactical systems and expand capability for peak performance. We challenge assumptions and identify overlooked problems that impact the human factor so we can develop innovative, quality products to fill those needs. Every detail gives you an edge through efficiency, comfort and safety so you can focus on the mission.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.unitytactical.com/"
                    target="_blank"
                  >
                    www.unitytactical.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://vaulteksafe.com/" target="_blank">
                <img
                  className="EsEliteImg1"
                  src={IMAGES.Vaultek}
                  alt="Vaultek Safe"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Vaultek Safe</div>
              <div className="EsEliteTextContent">
              Vaultek is an American security technology company specializing in portable personal security manufacturing advanced smart safes with industry leading innovation.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://vaulteksafe.com/"
                    target="_blank"
                  >
                    vaulteksafe.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          {/* <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.strohmanenterprise.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT8}
                  alt="Strohman Enterprise"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Strohman Enterprise</div>
              <div className="EsEliteTextContent">
                Strohman Enterprise embodies a team of personable, hard working,
                and knowledgeable employees. Our company focuses on what is most
                important; giving back to those who give. We do this by
                developing strong customer relationships as well as ensuring
                dependable service and product knowledge. We devote the time and
                energy it takes to answer any questions and help guide our
                customers to find and procure a product that best suits their
                needs. Our mission is to distribute quality products to LE,
                Military, First responders, and organizations that offer
                assistance to those who dedicate their lives to others.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.strohmanenterprise.com/"
                    target="_blank"
                  >
                    www.strohmanenterprise.com
                  </a>
                </span>
              </div>
            </div>
          </div> */}

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://inforcelights.com/" target="_blank">
                <img className="EsEliteImg" src={IMAGES.AT9} alt="Inforce" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Inforce</div>
              <div className="EsEliteTextContent">
                Emissive Energy Corp. is an American manufacturer that designs
                and produces weapon accessories for defense, law enforcement,
                homeland security and private citizens marketed under our
                INFORCE brand. We are an ISO 9001:2008 certified manufacturer of
                optoelectronic systems that also designs, engineers and
                manufactures OEM products for a variety of U.S. and
                international customers. The INFORCE brand of products is
                distributed worldwide and is currently issued by every branch of
                the U.S. Military and by Federal, State and local law
                enforcement agencies.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://inforcelights.com/"
                    target="_blank"
                  >
                    www.inforce-mil.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.mossberg.com/" target="_blank">
                <img className="EsEliteImg" src={IMAGES.AT10} alt="Mossberg" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Mossberg</div>
              <div className="EsEliteTextContent">
                Since 1919, Mossberg® has been the leader in introducing
                important design breakthroughs to the firearm industry. Many
                product features pioneered by Mossberg® are now the standards by
                which all modern firearms are judged. Never content with the
                status quo, our men and women constantly strive to improve and
                update the capabilities of all Mossberg® products. In
                accomplishing this goal, we recognize that each improvement is a
                part of the “Shooting System” approach and most are designed to
                fit the shotgun you bought years ago.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.mossberg.com/"
                    target="_blank"
                  >
                    www.mossberg.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.nosler.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT11}
                  alt="Nosler Ammunition"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Nosler Ammunition</div>
              <div className="EsEliteTextContent">
                With the company motto “Quality First,” Nosler manufactures
                premium component bullets, brass, ammunition, and semi-custom
                rifles for domestic and international customers. Our Products
                lead the pack, because they're built with equal parts ingenuity
                and integrity, passion and precision, consistency and
                confidence.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.nosler.com/"
                    target="_blank"
                  >
                    www.nosler.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.pelican.com/" target="_blank">
                <img className="EsEliteImg" src={IMAGES.AT12} alt="Pelican" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Pelican</div>
              <div className="EsEliteTextContent">
                rom this humble beginning sprang a company that ultimately
                became the global leader in the design and manufacture of
                high-performance protective cases, temperature controlled
                packaging and advanced portable lighting systems. All along the
                way, Pelican has stayed true to Dave’s commitment to quality and
                his vision: Protecting All That You Value. We take great pride
                in the products we’ve created over the years for police,
                firefighters, military personnel, photographers and others who
                have counted on Pelican products to protect their gear and their
                lives.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.pelican.com/"
                    target="_blank"
                  >
                    www.pelican.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://rcsgear.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT13}
                  alt="Raven Concealment Systems"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Raven Concealment Systems</div>
              <div className="EsEliteTextContent">
                Raven Concealment Systems specializes in providing holsters,
                magazine carriers, and related accessories for individuals that
                require secure and discreet means to carry weapons in daily
                life. RCS products are the officially-issued concealment holster
                for numerous units in the U.S. and friendly foreign militaries,
                multiple federal law enforcement agencies, and countless
                executive/personal protection details. By choosing Raven
                Concealment Systems products to conceal your daily-carry pistol,
                you are choosing the same holster that is used to discreetly
                protect Fortune 500 executives, diplomatic personnel, and
                heads-of-state around the globe.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://rcsgear.com/"
                    target="_blank"
                  >
                    www.rcsgear.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.salomon.com/en-int" target="_blank">
                <img className="EsEliteImg" src={IMAGES.AT14} alt="Salomon" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Salomon</div>
              <div className="EsEliteTextContent">
                Salomon has been playing in the French alps since 1947. Our
                passion for outdoor sports, new technologies and craftsmanship
                has driven us - and still does - to create progressive gear to
                enable you to freely enjoy and challenge yourself in the great
                outdoors. Don’t wait any longer. Now, it’s time to play.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.salomon.com/en-int"
                    target="_blank"
                  >
                    www.salomon.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.sigsauer.com/" target="_blank">
                <img className="EsEliteImg" src={IMAGES.AT15} alt="Sig Sauer" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Sig Sauer</div>
              <div className="EsEliteTextContent">
                SIG SAUER® world renowned firearms are the weapons of choice for
                many of the premier global military, law enforcement and
                commercial users. High quality, ultimate reliability and
                unmatched performance have always been hallmarks of the SIG
                SAUER brand. In the USA, nearly 1 in 3 law enforcement
                professionals use SIG SAUER firearms.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.sigsauer.com/"
                    target="_blank"
                  >
                    www.sigsauer.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          {/* <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.silencerco.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT16}
                  alt="Silencer Co."
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Silencer Co.</div>
              <div className="EsEliteTextContent">
                SilencerCo has grown to produce more silencers than anyone else
                in the market. SilencerCo sets the standard for quality and
                innovation. We created the first non-round pistol silencer with
                the Osprey, the quietest and easiest cleaning rimfire silencer
                with the Sparrow, the only commercially-viable, fully-modular
                shotgun silencer in existence with the Salvo 12, and now the
                first integrally suppressed 9mm pistol that is holsterable and
                hearing safe with all factory ammunition – the Maxim 9.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.silencerco.com/"
                    target="_blank"
                  >
                    www.silencerco.com
                  </a>
                </span>
              </div>
            </div>
          </div> */}

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://vertx.com/" target="_blank">
                <img className="EsEliteImg" src={IMAGES.AT17} alt="Vertx" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Vertx</div>
              <div className="EsEliteTextContent">
                The Vertx story started when a top federal agency directly
                requested a more functional, comfortable and versatile low
                profile tactical pant. Leveraging over 175 years of experience,
                the Fechheimer Brothers Company worked side-by-side with
                operators to develop the industry’s first tactical pant that
                combined superior functionality with a low visual profile. After
                an incredible reception by the industry, Vertx continued to
                utilize world-class designers to develop low profile apparel,
                tactical garments and low profile concealed carry bags and
                packs. Today, Vertx is trusted by the nation’s top operators and
                prepared professionals for our innovative designs,
                functionality, fit and versatility.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://vertx.com/"
                    target="_blank"
                  >
                    www.vertx.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://xssights.com/" target="_blank">
                <img className="EsEliteImg" src={IMAGES.AT18} alt="XS Sights" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">XS Sights</div>
              <div className="EsEliteTextContent">
                Our family strives to lead an even stronger more capable team
                with the same family passion and traditions we were founded on.
                We are deeply committed to creating and manufacturing products
                to help the GOODGUY take care of bad situations. With this we
                honor and support our Law Enforcement and Military professionals
                who protect us at home and abroad. We highly encourage firearm
                training and education and are proud to support organizations
                and our fellow citizens who believe in our Constitution.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://xssights.com/"
                    target="_blank"
                  >
                    www.xssights.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://blackriverdefensivesolutions.com/" target="_blank">
                <img className="EsEliteImg" src={IMAGES.BlackRiver} alt="Black River Defensive Solutions" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Black River Defensive Solutions</div>
              <div className="EsEliteTextContent">
              The mission at Black River Defensive Solutions is to provide Medical and Firearms Training and Consultation. Providing next level firearms  training for responsible gun owners in the fundamentals of marksmanship, NJ Concealed Carry and the responsibility that comes with it, and developing skillsets for every day carry (EDC).
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://blackriverdefensivesolutions.com/"
                    target="_blank"
                  >
                    blackriverdefensivesolutions.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          {/* <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://timneytriggers.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT19}
                  alt="Timney Triggers"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Timney Triggers</div>
              <div className="EsEliteTextContent">
                Our aim as one of the oldest and largest trigger manufacturers
                in the world, as well as being a family owned business for two
                generations, is to provide you with a lifetime of accurate
                shooting by manufacturing the very best quality precision
                trigger. We firmly believe our manufacturing process utilizes
                the best of the modern workplace: human spirit, knowledge and
                skill combined with cutting edge machinery and technology.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://timneytriggers.com/"
                    target="_blank"
                  >
                    timneytriggers.com
                  </a>
                </span>
              </div>
            </div>
          </div> */}

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://unchartedsupplyco.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT20}
                  alt="Uncharted Supply Co."
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Uncharted Supply Co.</div>
              <div className="EsEliteTextContent">
                Founded in Nov 2016, Park City UT’s Uncharted Supply Co’s
                mission is to provide high-quality, intuitive gear to help
                people navigate emergencies big and small. Uncharted’s first
                product, The Seventy2 Survival System, reimagined the personal
                preparedness space and launched an industry. Uncharted has
                continued to design products that have saved lives around the
                world. Today, Uncharted’s expanded product line is available in
                over 200 retail stores, and provides coverage for the adventurer
                as well as those looking to prepare for an ever-changing world.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://unchartedsupplyco.com/"
                    target="_blank"
                  >
                    unchartedsupplyco.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          {/* <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="http://reptiliacorp.com/" target="_blank">
                <img className="EsEliteImg" src={IMAGES.AT21} alt="Reptilia" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Reptilia</div>
              <div className="EsEliteTextContent">
                For more than a decade, our team has worked in product design,
                marketing, and business development for some of the most
                innovative brands in the firearms industry, including Magpul,
                Advanced Armament Corp., SilencerCo, Crye Precision, Radian and
                others. In an industry where every new product is touted as a
                “gamechanger”, we’re taking a different approach, honesty and
                integrity.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="http://reptiliacorp.com/"
                    target="_blank"
                  >
                    reptiliacorp.com
                  </a>
                </span>
              </div>
            </div>
          </div> */}

          {/* <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.steiner-optics.com/" target="_blank">
                <img className="EsEliteImg" src={IMAGES.AT22} alt="Steiner" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Steiner</div>
              <div className="EsEliteTextContent">
                For Steiner, superb performance and bone-deep tenacity was born
                in the ruin of war. In 1947, in the face of demolished
                infrastructures and a collapsed economy, Karl Steiner started a
                one-man workshop in Bayreuth, Germany, obsessed with a single
                goal: creating optical products so functional, sturdy and
                uniformly flawless that nothing in the chaotic, mass-production
                post-war world could compare. His passion for unmatched optical
                perfection pushed Steiner-Optik from a hardscrabble startup to a
                50-man factory within six years, and on to become a worldwide
                optical icon with binoculars and rifle scopes for every purpose,
                a history of innovation, and legions of diehard enthusiasts in
                every category.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.steiner-optics.com/"
                    target="_blank"
                  >
                    www.steiner-optics.com
                  </a>
                </span>
              </div>
            </div>
          </div> */}

          <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.us-elitegear.com/" target="_blank">
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT23}
                  alt="U.S. Elite"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">U.S. Elite</div>
              <div className="EsEliteTextContent">
                We exist to Enhance the Warrior Lifestyle — the spirit that
                defines those brave, committed and intrepid souls who seek to
                push themselves to their greatest limits and beyond. We honor,
                serve and respect these fine men and women by helping them to
                achieve and enhance their desired level of performance, safety,
                comfort and all around lifestyle.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.us-elitegear.com/"
                    target="_blank"
                  >
                    www.us-elitegear.com
                  </a>
                </span>
              </div>
            </div>
          </div>

          {/* <div className="EsEliteImgText">
            <div className="EsEliteImgDiv">
              <a href="https://www.anomie.tech/" target="_blank">
                <img className="EsEliteImg" src={IMAGES.AT24} alt="Anomie" />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Anomie</div>
              <div className="EsEliteTextContent">
                Anomie Technologies designs and manufactures secure
                communications solutions for high-value personnel traveling
                abroad.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://www.anomie.tech/"
                    target="_blank"
                  >
                    www.anomie.tech
                  </a>
                </span>
              </div>
            </div>
          </div> */}

          <div className="EsEliteImgText" style={{border:"none"}}>
            <div className="EsEliteImgDiv">
              <a
                href="https://sorenson.com/captioned-calls/captioning/"
                target="_blank"
              >
                <img
                  className="EsEliteImg"
                  src={IMAGES.AT25}
                  alt="Captioncall by Sorenson"
                />
              </a>
            </div>
            <div className="EsEliteTextBox">
              <div className="EsEliteTextTitle">Captioncall by Sorenson</div>
              <div className="EsEliteTextContent">
                A global language services provider combining patented
                technology and human-centric services to connect people across
                signed and spoken languages through interpreting and captioning
                solutions.
                <span>
                  <a
                    className="EsEliteTextLink"
                    href="https://sorenson.com/captioned-calls/captioning/"
                    target="_blank"
                  >
                    sorenson.com/solutions/captioning
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExhibitingSponsors;
