import React from "react";
import "./Footer.css";
import IMAGES from "../../assets";

function Footer() {
  return (
    <div>
      <div className="FParentDiv">
        <div className="FGrayDiv">
          <div className="ContactUSDetails">
            <div className="ContactUSDetailsTitle">
              <div className="ContactUsImageIcons">
                <img className="ContactUsImage" src={IMAGES.Location} alt="location" />
              </div>
              <div>Office:</div>
            </div>
            <div>New York, NY - Undisclosed Location</div>
          </div>
          <div className="ContactUSDetails">
            <div className="ContactUSDetailsTitle">
              <div className="ContactUsImageIcons">
                <img className="ContactUsImage" src={IMAGES.Whatsapp} alt="location" />
              </div>
              <div>Call:</div>
            </div>
            <div>(914) 760 - 9853</div>
          </div>
          <div className="ContactUSDetails">
            <div className="ContactUSDetailsTitle">
              <div className="ContactUsImageIcons">
                <img className="ContactUsImage" src={IMAGES.Email} alt="location" />
              </div>
              <div>Email:</div>
            </div>
            <div>diane.barton@atcouncil.us</div>
          </div>
        </div>

        <div className="FRedDiv">
          <div>
            <img
              src={IMAGES.ATCLogo}
              className="FATCImg"
              alt="footer logo ATC"
            />
          </div>
          <div className="FooterText">Copyright 2024. All rights reserved</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
