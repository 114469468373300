import React, { useEffect } from "react";
import "./SpeakerRoster.css";
import IMAGES from "../../assets";
import { Helmet } from "react-helmet-async";


function SpeakerRoster() {
  useEffect(() => {
    document.title = "Speaker Roster";
  }, []);
  return (
    <div>
       <Helmet>
        <title></title>
        <meta name="description" content="SPEAKER ROSTER, Dr. Maurizio Miglietta - Director,Michael Vaiani - Founder, Diane Barton - Event Coordinator, Marc Polymeropoulos - CIA retired, Michèle Stuart"/>
        <link rel="canonical" href="/speaker-roster"/>
      </Helmet>
      <div className="SrEliteMainDiv">
        <div className="SrEliteContainer">
          <div className="SrEliteTitle">
            <div className="SrELiteTitleText">SPEAKER ROSTER</div>
            <div className="SrOurHostText">OUR HOSTS</div>
          </div>
          <div className="SrEliteImgText">
            <div className="SrEliteImgDiv">
              <img
                className="SrEliteImg"
                src={IMAGES.SR1}
                alt="Dr. Maurizio Miglietta"
              />
            </div>
            <div className="SrEliteTextBox">
              <div className="SrEliteTextTitle">
                Dr. Maurizio Miglietta - Director
              </div>
              <div className="SrEliteTextContent">
                Dr. Miglietta is a Trauma Surgeon and serves as the Founder and
                Director of the MTU. He has served as Chief of the Division of
                Acute Care Surgery and Director of Trauma and Emergency Surgery
                at NewYork-Presbyterian Hospital / Columbia University Medical
                Center. Immediately prior, he served as Chief of Trauma and
                Critical Care at the renowned and historic Bellevue Hospital /
                New York University School of Medicine.
              </div>
              <div className="SrEliteTextContent1">
                Dr. Miglietta provided medical support for the United States
                Secret Service – New York Field Office, Diplomatic Security
                Service - State Department and continues to be an Honorary
                Police Surgeon for the New York City Police Department. Dr.
                Miglietta is a Professor of Surgery and Associate Dean at Touro
                University and served as a Professor of Surgery at NYU, Columbia
                and Mount Sinai Schools of Medicine. He served as the Director
                of Medical Education at Hackensack Meridian Palisades and
                created multiple residency training programs. He has
                approximately 30 peer-reviewed publications, appears as a
                medical expert on various national TV shows and has trained
                various federal agencies. Dr. Miglietta has received awards from
                the Federal Law Enforcement Association and Homeland Security
                Foundation for his continued work with law enforcement. Dr.
                Miglietta was honored as one of the 2008 Crain’s Business Forty
                Under 40. Dr. Miglietta is a Special Deputy U.S. Marshal
                assigned to the NY/NJ Fugitive Task Force.
              </div>
            </div>
          </div>

          <div className="SrEliteImgText">
            <div className="SrEliteImgDiv">
              <img
                className="SrEliteImg"
                src={IMAGES.SR2}
                alt="Michael Vaiani - Founder, NYC Anti Terrorism Symposium"
              />
            </div>
            <div className="SrEliteTextBox">
              <div className="SrEliteTextTitle">
                Michael Vaiani - Founder
              </div>
              <div className="SrEliteTextContent">
                Mike Vaiani is the founder of the NYC Anti-Terrorism Symposium
                is 2002. He is a (Ret.) Special Agent, USSS assigned to the New
                York Field Office located at #7 World Trade Center on the
                morning of 9/11. His life experiences to include a degree in
                nursing, uniform police officer and a special agent with DEA has
                helped him build relationships through out his career and
                understands the importance of it. During the events of 9/11,
                Mike realized relationships established could form a networking
                opportunity through training for not only law enforcement, first
                responders, military and private sector. This mixing of dynamic
                individuals from the various sectors along with an expertise of
                speakers makes the Symposium a truly unique experience. Mikes
                love for the military comes from his dad's career in the Navy as
                a (Ret.) Navy Chief.
              </div>
            </div>
          </div>

          <div className="SrEliteImgText" style={{ border: "none" }}>
            <div className="SrEliteImgDiv">
              <img
                className="SrEliteImg"
                src={IMAGES.DianeChange}
                alt="Diane Barton - Coordinator, NYC Anti Terrorism Symposium"
              />
            </div>
            <div className="SrEliteTextBox">
              <div className="SrEliteTextTitle">
                Diane Barton - Event Coordinator
              </div>
              <div className="SrEliteTextContent">
                When you think of the MTU NYC Anti Terrorism Symposium, you
                can’t help but think of Diane. As the Symposium Coordinator
                since 2007, Diane has handled everything from flights to food to
                schedules and accommodations. Diane takes the stresses of
                managing many diverse requests & requirements, let alone dealing
                with many military and law enforcement divisions, & turns them
                into interesting, informative and fun events with a family like
                feel. Initially working with USSS Special Agent Mike Vaiani at
                Camp Smith in 2001, Diane became interested in learning about
                the Symposium. Soon, she enthusiastically volunteered to help.
              </div>
              <div className="SrEliteTextContent1">
                Diane currently is Housing and Logistics Billeting Manager at
                Camp Smith Training Site for DMNA where she is responsible for
                the management, administration, & technical & professional
                direction of lodging operations as well as coordinating major
                activities for lodging projects & soldier training. Her work
                ensures measurable standards of service performance &
                accountability to ensure a uniform system that provides
                cost-effective service to all service members.
              </div>
              <div className="SrEliteTextContent1">
                Diane and her husband Kevin love spending time with their large,
                boisterous family, especially their three grandkids and three
                grand fur babies who keep them on their toes!
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="OURSPEAKERS">OUR SPEAKERS</div>

      <div className="SrEliteMainDiv">
        <div className="SrEliteContainer">
          <div className="SrEliteImgText">
            <div className="SrEliteImgDiv">
              <img
                className="SrEliteImg"
                src={IMAGES.SR4}
                alt="Marc Polymeropoulos - CIA retired"
              />
            </div>
            <div className="SrEliteTextBox">
              <div className="SrEliteTextTitle">
                Marc Polymeropoulos - CIA retired
              </div>
              <div className="SrEliteTextContent">
                Marc Polymeropoulos worked for 26 years at the CIA before
                retiring in July 2019 at the Senior Intelligence Service level.
                He served in multiple field assignments for the U.S. government,
                and specialized in counterterrorism, the Middle East and South
                Asia, including extensive time in Iraq and Afghanistan. Prior to
                his retirement, Marc served at CIA headquarters and was in
                charge of CIA’s clandestine operations in Europe and Eurasia.
                Marc is the recipient of the Distinguished Career Intelligence
                Medal, the Distinguished Intelligence Medal, the Intelligence
                Medal of Merit, the Intelligence Commendation Medal, and the
                George Bush Award for Excellence in Counterterrorism.
              </div>
              <div className="SrEliteTextContent1">
                He frequently comments on international events in the US and
                international media, to include the Washington Post, The New
                York Times, the Wall Street Journal, and the Economist. He is an
                on-air intelligence and national security analyst for MSNBC, and
                is also a non-resident senior fellow at the Atlantic Council,
                specializing in hybrid warfare. He is an advisor to Spycraft
                Entertainment, a global production company.
              </div>
              <div className="SrEliteTextContent1">
                Marc is a sought-after leadership speaker; his book “Clarity in
                Crisis: Leadership Lessons from the CIA” was published in 2021
                by Harper Collins. Marc received his undergraduate and graduate
                degrees from Cornell University. He splits his time between
                Northern Virginia and the Outer Banks of North Carolina.
              </div>
            </div>
          </div>

          <div className="SrEliteImgText">
            <div className="SrEliteImgDiv">
              <img
                className="SrEliteImg"
                src={IMAGES.SR5}
                alt="Michèle Stuart - JAG Investigations, Inc."
              />
            </div>
            <div className="SrEliteTextBox">
              <div className="SrEliteTextTitle">
                Michèle Stuart - JAG Investigations, Inc.
              </div>
              <div className="SrEliteTextContent">
                With over thirty years of investigative experience and
                twenty-six years of running her own company, Ms. Stuart is an
                expert in a range of investigative fields, including OSINT,
                counterintelligence, insurance fraud investigations, financial
                investigations, threat assessments, due diligence, organized
                retail crime, corporate and competitive intelligence.
              </div>
              <div className="SrEliteTextContent1">
                Ms. Stuart consults and trains a variety of clients, including
                federal, state, and local law enforcement agencies, military
                intelligence communities, Fortune 500 companies, as well as
                financial and insurance industries. Her focus is on open source
                intelligence, social media, and threat assessments and
                mitigation. She has also served as an Instructor at Quantico
                (FBI Academy), teaching international training in OSINT
                (Open-Source Intelligence).
              </div>
              <div className="SrEliteTextContent1">
                In 2017, Ms. Stuart collaborated with the Pennsylvania Office of
                Homeland Security to create a program called “Keeping Kids
                Safe.” This program provides training to administrators,
                principals, teachers, SRO’s, and parents on the dangers of
                online and social media activity, predator grooming, as well as
                the dangers of applications and cellular security.
              </div>
            </div>
          </div>

          <div className="SrEliteImgText">
            <div className="SrEliteImgDiv">
              <img
                className="SrEliteImg"
                src={IMAGES.SR6}
                alt="Mike Failace - The Suffering Podcast"
              />
            </div>
            <div className="SrEliteTextBox">
              <div className="SrEliteTextTitle">
                Mike Failace - The Suffering Podcast
              </div>
              <div className="SrEliteTextContent">
                Mike Failace started his law enforcement career in January 1996
                with the Bergen County Sheriff's Department. He worked in
                various positions including court security, civil process,
                dignitary protection, and eventually the violation of probation
                warrant unit. He transferred to the Lyndhurst Police Department
                in August 1999. He was promoted to Detective in 2001, Sergeant
                in 2009 and Lieutenant in 2014.
              </div>
              <div className="SrEliteTextContent1">
                While with the Lyndhurst Police Department, he was a member of
                the departments Honor Guard, Junior Police Academy Instructor,
                and Special Response Team. As a team leader on the SRT, Mike was
                a sub machine gun operator and chemical munitions and flash bang
                handler. He is a certified police training commission physical
                conditioning instructor and also taught classes in the Bergen
                County Police Academy such as motor vehicle stops, crimes in
                progress, snipers ambushes and assaults.
              </div>
              <div className="SrEliteTextContent1">
                In September 2014, Mike was involved in a fatal shooting which
                led to his retirement. Now Mike is a proud co-host of The
                Suffering Podcast.
              </div>
            </div>
          </div>

          <div className="SrEliteImgText">
            <div className="SrEliteImgDiv">
              <img
                className="SrEliteImg"
                src={IMAGES.SR7}
                alt="Chris DeMunbrun - Blade Solutions"
              />
            </div>
            <div className="SrEliteTextBox">
              <div className="SrEliteTextTitle">
              Chris DeMunbrun - Blade Solutions
              </div>
              <div className="SrEliteTextContent">
              Chris DeMunbrun is the Founder and Managing Director of Blade
                Solutions, a IT/Cyber firm which offers managed security
                services centered around a 24x7 front and back line global
                security operation center (SOC) run by former NSA and Military
                personnel.
              </div>
              <div className="SrEliteTextContent1">
              Before entering the private sector in 2017, Chris had an
                illustrious decade-long career as a Special Agent for the United
                States Secret Service. With specializations in Cyber
                Investigations and Digital Forensics, he expertly managed the
                New York Electronic Crimes High Intensity Drug Trafficking Areas
                (HIDTA) Forensic Lab. His noteworthy contributions to national
                security culminated in a prestigious assignment to the
                Presidential Protective Division (PPD), where he served under
                the administrations of President Obama and President Trump.
              </div>
              <div className="SrEliteTextContent1">
              Currently, Chris applies his extensive knowledge and experience
                in Cyber Security, Research and Development, Applied
                Technologies, and Intelligence to manage clients all over the
                world, in multiple verticals. His firm is at the forefront of
                offering innovative services that support and enhance the
                security sector. Chris’s unique blend of federal service and
                cutting-edge technological insight places him at the pinnacle of
                the industry, where he continues to drive progress and
                excellence.
              </div>
            </div>
          </div>

          <div className="SrEliteImgText">
            <div className="SrEliteImgDiv">
              <img
                className="SrEliteImg"
                src={IMAGES.SR8}
                alt="Kevin Donaldson - Law Enforcement Officer, Host Podcast"
              />
            </div>
            <div className="SrEliteTextBox">
              <div className="SrEliteTextTitle">
              Kevin Donaldson - Law Enforcement Officer, Host Podcast
              </div>
              <div className="SrEliteTextContent">
              Surviving post traumatic stress and multiple suicide attempts, Kevin P. Donaldson found resiliency in the embrace of his trauma. Retiring from the police department in New Jersey in 2014 after being involved in a shooting during a domestic incident, Kevin struggled to find peace inside his own chaotic mind dealing with feelings that were foreign and new while fighting through the difficulties that life threw his way. Kevin now Chronicles his struggles by speaking out and destigmatizing mental health to find resiliency in facing suffering head-on by showing that there is a way to find your smile once again. Find Kevin's work and dedication to face the truth as the host of The Suffering Podcast
              </div>
              <div className="SrEliteTextContent1">
              His work now includes his dedication to repaying the assistance that was given during his most trying of times with His Non-Profit organization Dented Development Project which helps first responders and their families repair dents caused by Suffering. In 2022, Kevin was honored by being named to Who's Who in America.
              </div>
              <div className="SrEliteTextContent1">
              Kevin P. Donaldson is known for transforming the lives of audiences. Kevin's approach toward finding success and healing is approached by embracing your pain and running towards it head on.
              </div>
              <div className="SrEliteTextContent1">
              "There is always hope, you maybe scared, maybe scarred, but you will make it through and ultimately you will be alive” 
              </div>
            </div>
          </div>

          <div className="SrEliteImgText" style={{ border: "none" }}>
            <div className="SrEliteImgDiv">
              <img
                className="SrEliteImg"
                src={IMAGES.SR9}
                alt="Christopher Huttleson - Central Intelligence Agency Retired"
              />
            </div>
            <div className="SrEliteTextBox">
              <div className="SrEliteTextTitle">
              Christopher Huttleson - Central Intelligence Agency Retired 
              </div>
              <div className="SrEliteTextContent">
              Christopher Huttleston spent more than 23 years serving this country, first as a senior noncommissioned officer in the US Army and then as a Senior Operations Officer in CIA’s Directorate of Operations.
              </div>
              <div className="SrEliteTextContent1">
              On active duty with the US Army, Christopher deployed to Bosnia-Hercegovina with 165th MI BN (attached to 1st Armored Division) and to locations in the Middle East and Africa with 5th Special Forces Group.
              </div>
              <div className="SrEliteTextContent1">
              With the CIA, Christopher was assigned to the Near East Division and deployed to the Middle East as a clandestine operative three weeks after September 11th 2001, serving five consecutive field tours undercover in the Middle East and North Africa. Christopher returned to CIA HQs in late 2011 and worked in senior operational leadership roles in CIA’s Counterterrorism Center and engaged directly with partners throughout the military, intelligence, and law enforcement communities, and with numerous foreign allies and their security services.
              </div>
              <div className="SrEliteTextContent1">
              Christopher retired from the CIA in July 2017 and his cover was officially lifted shortly thereafter. He is a lifetime member of the Veterans of Foreign Wars and is actively involved in charity and veterans’ groups.
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default SpeakerRoster;
