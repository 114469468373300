import React, { useEffect, useState } from "react";
import "./LearnMore.css";
import IMAGES from "../../assets";
import { Helmet } from "react-helmet-async";


function LearnMore() {
    // State to track screen width
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
  
    useEffect(() => {
      document.title = "Learn More";
  
      // Function to handle window resize
      const handleResize = () => {
        setIsMobile(window.innerWidth < 600);
      };
  
      // Add event listener to track window resize
      window.addEventListener("resize", handleResize);
  
      // Cleanup function to remove event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  return (
    <div className="LearnUs">
      <Helmet>
        <title></title>
        <meta name="description" content="INVITATION FROM MIKE VAIANI, DIANE BARTON AND
DR. MAURIZIO MIGLIETTA. Hors D'oeuvres, Open Bar & 3 Course Seated Dinner & Dialogue with Tony Rizzoro"/>
        <link rel="canonical" href="/learn-more"/>
      </Helmet>
      <div className="LearnUsMain">
        <div className="LearnUsFirst">
          {/* <div className="LearnUsFirstTxt1">ABOUT US</div> */}
          <div className="LearnUsFirstTxt2">INVITATION FROM MIKE VAIANI, DIANE BARTON AND</div>
          <div className="LearnUsFirstTxt22">DR. MAURIZIO MIGLIETTA</div>
          <div>
          <div className="LearnUsDiv">
              <img
                src={IMAGES.NYCANNUAL}
                alt="About Us Images"
                className="LearnUsImages"
              />
            </div>
            <div className="Margindate"></div>
            <div className="Benjamin">
                <div className="eastText">
                BENJAMIN PRIME STEAKHOUSE
                </div>
            </div>
            <div className="Benjamin">
                <div className="eastText">
                23 East 40th Street, New York, New York 10016
                </div>
            </div>
            <div className="Margindate"></div>
            <div className="BenjaminDate">
                <div className="eastTextDate">
                TUESDAY, November 12th, 2024
                </div>
            </div>
            <div className="BenjaminDate">
                <div className="eastTextDate">
                5:30 PM
                </div>
            </div>
            <div className="BenjaminAddress">
                <div className="eastTextAddress">
                Hors D'oeuvres, Open Bar & 3 Course Seated Dinner
                </div>
            </div>
            <div className="BenjaminAddress">
                <div className="eastTextAddress">
                Dinner & Dialogue with
                </div>
            </div>
            <div className="endimage"></div>
            <div className="LearnUsDiv">
            <img
                src={isMobile ? IMAGES.TonyLM : IMAGES.TonyLearnMore} // Mobile or Desktop Image
                alt="About Us Images"
                className="LearnUsImages"
              />
            </div>
            <br/>

            <div className="jaycristy">
                <div className="jaycristytext">
                AND JAY CHRISTY
                </div>
            </div>
            <div className="jaycristy">
                <p className="jaycristyP">
                Jay Christy is a 20-year veteran of the U.S. Army. Jay was an Army Ranger then made selection to enter Delta Force. Jay did five years as a Delta Force Operator. Jay’s final stop in the Army was as a Captain on the US Army, Marksmanship Action Pistol Team.
                </p>
            </div>
            {/* <div className="priceabove"></div> */}
            <div className="Support">
                <div>
                <p className="SupportText">
                THIS EVENT IS IN SUPPORT OF THE NYC-ATS, <br/>A 501C3 ORGANIZATION
                </p>
                </div>
            </div>

            <div className="Support">
                <div>
                <p className="SupportText">
                $1,000. per plate <br/>or<br/>
                Table of 6 for $5,000.
                </p>
                </div>
            </div>
            <div className="endimage"></div>
            <div className="LearnUsDiv">
              <img
                src={isMobile ? IMAGES.TonyEndMobile : IMAGES.TonyLearnMoreEnd}
                alt="About Us Images"
                className="LearnUsImages"
              />
            </div>

            <div className="LastText">
                <div>
                <p className="LastTextImage">
                Thank you as always for continuous support to the NYC-ATS.<br/>
                If you have any questions regarding this event, please feel free to contact me via email or cell.
                </p>
                </div>
            </div>
          </div>
        </div>
        {/* <div className="LearnUsSecond">
          <div className="LearnUsSecondTxt1">SYMPOSIUM’S HISTORY</div>
          <div>
            <div className="LearnUsFirstP LearnUsSecondP">
              In it’s 22nd year, the Anti-Terrorism Council (ATC) brings
              together leaders & decision-makers in the field of law
              enforcement, government, business, medicine & the private sector.
            </div>
            <div className="LearnUsFirstP">
              The 1st Anti-Terrorism Symposium came about after United States
              Secret Service Special Agent Michael Vaiani used his Surefire
              flashlight during the events of 9/11 at the World Trade Center.
              Mike realized that the events and relationships established could
              form a networking opportunity for not only law enforcement but
              also for a wide variety of individuals. This mixing of dynamic
              individuals from the various sectors along with an expertise of
              speakers makes the Symposium a truly unique experience.
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default LearnMore;
