import React, { useEffect } from "react";
import "./Gallery.css";
import IMAGES from "../../assets";
import { Helmet } from "react-helmet-async";


function Gallery() {
  useEffect(() => {
    document.title = "Our Gallery";
  }, []);
  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="description" content="The New Jersey State Police Pipe Band, ATS Unit Scholarship Fund Presentation, Breakaway session at last year's Symposium, A view of last year's booths at the Symposium"/>
        <link rel="canonical" href="/gallery"/>
      </Helmet>
      <div className="GalaryFather">
        <div className="GalaryMain">
          <div className="GalarayHeader">
            <div className="GalarayHeaderTitle">OUR GALLERY</div>
            <div className="GalarayHeaderSubTitle">
              See some of our photos from past years' events!
            </div>
          </div>
          <div className="GalaryTwoImages">
            <div className="GalaryRow1ImagesDiv1">
              <img
                src={IMAGES.GalaryRow1}
                alt="Galary Row1 a Images"
                className="GalaryRow1Images"
              />
              <div className="GalaryRow1ImagesText">
              A view of last year's booths at the Symposium
              </div>
            </div>
            <div className="GalaryRow1ImagesDiv2">
              <img
                src={IMAGES.GalaryRow2}
                alt="Galary Row1 b Images"
                className="GalaryRow1Images"
              />
              <div className="GalaryRow1ImagesText">
              Breakaway session at last year's Symposium
              </div>
            </div>
          </div>
          <div className="GalaryThreeImages">
            <div className="GalaryThreeImagesDiv1">
              <img
                src={IMAGES.GalaryRow2a}
                alt="Galary Row1 a Images"
                className="GalaryThreeImgs"
              />
              <div className="GalaryRow1ImagesText">
              ATS Unit Scholarship Fund Presentation
              </div>
            </div>
            <div className="GalaryThreeImagesDiv2">
              <img
                src={IMAGES.GalaryRow2b}
                alt="Galary Row1 b Images"
                className="GalaryThreeImgs"
              />
              <div className="GalaryRow1ImagesText">
              Cigar Night
              </div>
            </div>
            <div className="GalaryThreeImagesDiv3">
              <img
                src={IMAGES.GalaryRow2c}
                alt="Galary Row1 b Images"
                className="GalaryThreeImgs"
              />
              <div className="GalaryRow1ImagesText">
              The New Jersey State Police Pipe Band
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
