import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import MainLayout from "./component/MainLayout";
import About from "./pages/About/About";
import SpeakerRoster from "./pages/SpeakerRoster/SpeakerRoster";
import EliteSponsors from "./pages/EliteSponsor/EliteSponsors";
import ExhibitingSponsors from "./pages/ExhibitingSponsors/ExhibitingSponsors";
import Partners from "./pages/Partners/Partners";
import Gallery from "./pages/Gallery/Gallery";
import Events from "./pages/Events/Events";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import LearnMore from "./pages/LearnMore/LearnMore";

function App() {

  const TRACKING_ID = 'G-EPTKGKRLHN';
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname == '/' ? '/Homepage' : window.location.pathname + window.location.search});
    // ReactGA.pageview(window.location.pathname == '/' ? '/Homepage' : window.location.pathname + window.location.search); 
  }, [])
  

  return (
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/events-1909" element={<Events />} />
          <Route path="/speaker-roster" element={<SpeakerRoster />} />
          <Route path="/elite-sponsors" element={<EliteSponsors />} />
          <Route path="/exhibiting-sponsor" element={<ExhibitingSponsors />} />
          <Route path="/about" element={<About />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/learn-more" element={<LearnMore/>} />
        </Routes>
      </MainLayout>
  );
}

export default App;
