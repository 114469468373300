import ATCLogo from './ATC.png';
import Location from './location24.png';
import Email from './email24.png';
import Whatsapp from './whatsapp24.png';
import Menu from './menu.png';
import Close from './close.png';
import TaskForceAntal from "./TaskForceAntal.jpeg";
import TunnelsToTowers from "./TunnelsToTowers.jpeg";
import GalaryRow1 from "./GalaryFirstRow1.jpeg";
import GalaryRow2 from "./GalaryFirstRow2.jpeg";
import GalaryRow2a from "./GalarySecondRow1.jpeg";
import GalaryRow2b from "./GalarySecondRow2.jpeg";
import GalaryRow2c from "./GalarySecondRow3.jpeg";
import EliteSa from "./EliteSa.jpeg";
import EliteSb from "./EliteSb.jpeg";
import EliteSc from "./EliteSc.png";
import EliteSd from "./EliteSd.jpeg";
import AT1 from "./AT1.jpeg";
import AT2 from "./AT2.jpeg";
import AT3 from "./AT3.jpeg";
import AT4 from "./AT4.png";
import AT5 from "./AT5.jpeg";
import AT6 from "./AT6.jpeg";
import AT7 from "./AT7.jpeg";
import AT8 from "./AT8.jpeg";
import AT9 from "./AT9.jpeg";
import AT10 from "./AT10.jpeg";
import AT11 from "./AT11.jpeg";
import AT12 from "./AT12.jpeg";
import AT13 from "./AT13.jpeg";
import AT14 from "./AT14.jpeg";
import AT15 from "./AT15.jpeg";
import AT16 from "./AT16.jpeg";
import AT17 from "./AT17.jpeg";
import AT18 from "./AT18.jpeg";
import AT19 from "./AT19.jpeg";
import AT20 from "./AT20.jpeg";
import AT21 from "./AT21.jpeg";
import AT22 from "./AT22.jpeg";
import AT23 from "./AT23.jpeg";
import AT24 from "./AT24.jpeg";
import AT25 from "./AT25.jpeg";
import EmpireOriginalDesigns from "./EmpireOriginalDesign.png";
import ZenithAerotech from "./zenith.jpg";
import Unity from "./unity.jpg";
import Vaultek from "./Vaultek.png";
import BlackRiver from "./BlackRiver.jpg";
import SR1 from "./SR1.jpeg";
import SR2 from "./SR2.jpeg";
import SR3 from "./SR3.jpeg";
import SR4 from "./SR4.png";
import SR5 from "./SR5.png";
import SR6 from "./SR6.png";
import SR7 from "./SR7.png";
import SR8 from "./Kevin.png";
import SR9 from "./Christopher.png";
import HomeArmy from "./ArmyPic.png";
import Map from "./map.jpg";
import Maurizio from "./DrMaurizio.jpeg";
import Michael from "./Michael.jpeg";
import Diane from "./Diane.jpeg";
import DianeChange from "./DianeChange.jpeg";
import ATCAboutUs from "./ATCAboutUs.png";
import TonyLearnMore from "./TonyLearnMore.png";
import TonyLearnMoreEnd from "./TonyLMEnd.png";
import NYCANNUAL from "./AnnualNYC.png";
import TonyLM from "./TonyL.png";
import TonyEndMobile from "./ENDPICLM.png";

const IMAGES = {
    TonyEndMobile,
    TonyLM,
    NYCANNUAL,
    TonyLearnMoreEnd,
    TonyLearnMore,
    Maurizio,
    Michael,
    Diane,
    DianeChange,
    Map,
    ATCLogo,
    Location,
    Email,
    Whatsapp,
    Menu,
    Close,
    TaskForceAntal,
    TunnelsToTowers,
    Unity,
    Vaultek,
    BlackRiver,
    GalaryRow1,
    GalaryRow2,
    GalaryRow2a,
    GalaryRow2b,
    GalaryRow2c,
    EliteSa,
    EliteSb,
    EliteSc,
    EliteSd,
    EmpireOriginalDesigns,
    ZenithAerotech,
    AT1,
    AT2,
    AT3,
    AT4,
    AT5,
    AT6,
    AT7,
    AT8,
    AT9,
    AT10,
    AT11,
    AT12,
    AT13,
    AT14,
    AT15,
    AT16,
    AT17,
    AT18,
    AT19,
    AT20,
    AT21,
    AT22,
    AT23,
    AT24,
    AT25,
    SR1,
    SR2,
    SR3,
    SR3,
    SR4,
    SR5,
    SR6,
    SR7,
    SR8,
    SR9,
    HomeArmy,
    ATCAboutUs
}
export default IMAGES